<template lang="pug">
  v-container
    v-simple-table(dense)
      template(v-slot:default)
        thead
          tr
            th Type
            th In/Out
            th Cheque No.
            th Status
            th Date
            th.text-right Amount
        tbody
          tr(v-if="voucherTransactionByVoucherGetting")
            td(colspan="3").text-center
              v-progress-circular(
                color="primary"
                indeterminate
                size="30"
              )
          tr(v-else v-for="item in voucherTransactionByVoucherList" :key="item.id")
            td.text-uppercase 
              v-icon(
                  color="yellow darken-2"
                  @click="$emit('input', item)"
                ) mdi-pencil
              | {{ item.type }}
            td {{ item.amount < 0 ? 'In' : 'Out' }}
            td {{ item.cheque_number }}
            td(:class="statusColor(item) + '--text'") {{ item.status.description }}
            td {{ (item.date || '').dateFormat() }}
            td.text-right
              span(
                :class="item.amount > 0 ? 'red--text' : 'green--text'"
              ) {{ Math.abs(item.amount || 0).phpFormat() }}
          tr 
            th(colspan="5") Voucher Amount
            td.text-right {{ (voucherAmount || 0).phpFormat() }}
          tr
            th(colspan="5") Total Amount
            td.text-right {{ (totalAmount || 0).phpFormat() }}
          tr
            th(colspan="5") Balance
            td(
              :class="`text-right ${balanceNegativeColor}--text`"
            ) {{ balance.phpFormat() }}
</template>
<script>
import voucherTransactionRepository from '@/repositories/voucher-transaction.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherTransactionByVoucherVars, voucherTransactionByVoucherVarNames] = requestVars({ identifier: 'voucher-transaction-by-voucher' })

const voucherTransactionByVoucherHandler = new VueRequestHandler(null, voucherTransactionByVoucherVarNames)

export default {
  name: 'TableVoucherTransaction',
  props: ['voucherId', 'popup', 'value', 'voucherAmount'],
  data() {
    return {
      ...voucherTransactionByVoucherVars,
    }
  },
  created() {
    this.initWebsockets()
  },
  computed: {
    totalAmount() {
      return this.voucherTransactionByVoucherList.reduce((total, item) => {
        if (item.voucher_cheque_status_id === 'CN') return total
        return total + item.amount
      }, 0)
    },
    balance() {
      return this.voucherAmount - this.totalAmount
    },
    balanceNegativeColor() {
      if (this.balance >= 0) return null
      return 'red'
    },
  },
  watch: {
    voucherId(val) {
      this.voucherTransactionGetByVoucher()
    },
  },
  methods: {
    initWebsockets() {
      const { echo } = this.$store.state.websocket
      const reject = (model, data) => !model && this.$objectEmpty(data)
      echo.private('database.event')
        .listen('DBStoreEvent', ({ model, data }) => {
          if (reject(model, data)) return
          if (model === 'VoucherTransaction') {
            this.voucherTransactionByVoucherList.push(data)
          }
        })
        .listen('DBUpdateEvent', ({ model, data }) => {
          if (reject(model, data)) return
          if (model === 'VoucherTransaction') {
            const list = this.voucherTransactionByVoucherList
            this.voucherTransactionByVoucherList = list.map(item => {
              if (item.id === data.id) {
                return { ...item, ...data }
              }
              return { ...item }
            })
          }
        })
    },
    voucherTransactionGetByVoucher() {
      if (!this.voucherId) return
      const handler = voucherTransactionByVoucherHandler
      const repository = voucherTransactionRepository.getByVoucher
      handler.setVue(this)
      handler.execute(repository, [this.voucherId])
    },
    statusColor(item) {
      const status = item.voucher_cheque_status_id
      if (status === 'CP') return 'green'
      if (status === 'CN') return 'red'
      return 'primary'
    },
  },
}
</script>
